import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import { selectCategories } from '../redux/categories';
import { deleteExpense, updateExpense } from '../redux/expenses';
import { selectUsers } from '../redux/projects';

class EditExpenseDialog extends Component {
  constructor(props) {
    super(props);
    const { expense } = props;
    this.state = {
      ...expense,
      date: moment(expense.date, "DD-MM-YY").format("YYYY-MM-DD"),
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    this.setState({
      [target.name]: target.value
    });
  }

  handleDelete = () => {
    this.props.deleteExpense(this.state.id)
    this.props.onClose();
  }

   handleSubmit = (event) => {
     this.props.updateExpense({
       ...this.state,
       date: moment(this.state.date, "YYYY-MM-D").format("DD-MM-YY"),
       price: Number(this.state.price),
     });
     this.props.onClose();
   }

  render() {
    if (!this.props.categories || !this.props.users) {
      return null;
    }

    return (
      <Dialog
        fullScreen={this.props.fullScreen}
        open={this.props.open}
        onClose={this.props.onClose}>
        <DialogContent>
          <Grid container
            direction="column"
            alignItems="flex-end"
            spacing={24}>
            <Grid item container>
               <TextField label="Description"
                           name="desc"
                           type="text"
                           required
                           autoFocus
                           value={this.state.desc}
                           onChange={this.handleInputChange}
                           className={this.props.classes.textField}  />
           </Grid>
           <Grid item container>
             <TextField label="Price"
                     name="price"
                     type="number"
                     required
                     value={this.state.price}
                     onChange={this.handleInputChange}
                     className={this.props.classes.textField}  />
           </Grid>
           <Grid item container>
             <TextField
               select
               name="categoryId"
               label="Category"
               value={this.state.categoryId}
               onChange={this.handleInputChange}
               className={this.props.classes.textField}
             >
               {this.props.categories.map(option => (
                 <MenuItem key={option.id} value={option.id}>
                   {option.name}
                 </MenuItem>
               ))}
             </TextField>
           </Grid>
           <Grid item container>
             <TextField
               name="date"
               label="Date"
               type="date"
               required
               value={this.state.date}
               onChange={this.handleInputChange}
               className={this.props.classes.textField}
             />
           </Grid>
           <Grid item container>
             <TextField
               select
               name="userId"
               label="User"
               required
               value={this.state.userId}
               onChange={this.handleInputChange}
               className={this.props.classes.textField}
             >
               {Object.values(this.props.users).map(option => (
                 <MenuItem key={option.id} value={Number(option.id)}>
                   {option.name || option.email}
                 </MenuItem>
               ))}
             </TextField>
           </Grid>
         </Grid>
       </DialogContent>
      <DialogActions disableActionSpacing={true}>
        <Button onClick={this.props.onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={this.handleDelete} color="primary">
          Delete
        </Button>
        <Button onClick={this.handleSubmit} color="primary">
          Update
        </Button>
      </DialogActions>
      </Dialog>
    );
  }
}

const styles = theme => ({
  textField: {
    width: 300,
  }
});

const mapStateToProps = (state, ownProps) => {
  return {
    categories: selectCategories(state),
    users: selectUsers(state),
  };
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  deleteExpense,
  updateExpense,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withMobileDialog({breakpoint: 'xs'})(EditExpenseDialog)));
