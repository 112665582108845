import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';

import { logout, selectUser } from './redux/auth';

const styles = {
  root: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

class Header extends PureComponent {
  state = {
    anchorEl: null,
  };

  categories = () => {
    this.navigate('/categories');
  }

  recurring = () => {
    this.navigate('/recurring');
  }

  project = () => {
    this.navigate('/project');
  }

  importNav = () => {
    this.navigate('/import');
  }

  navigate(path) {
    this.handleClose();
    this.props.history.push(path);
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, logout, user } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Link to="/" className={classes.flex}>
              <Typography variant="title" color="inherit">
                  Expenses
                </Typography>
            </Link>
            <Link to="/explore">
              <Button color="inherit">Explore</Button>
            </Link>
            <Link to="/incomes">
              <Button color="inherit">Incomes</Button>
            </Link>
            <div>
              <IconButton
                aria-owns="menu-appbar"
                aria-haspopup="true"
                onClick={this.handleMenu}
                color="inherit"
              >
                <Avatar src={user.avatar} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                open={open}
                onClose={this.handleClose}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={this.categories}>Categories</MenuItem>
                <MenuItem onClick={this.recurring}>Recurring</MenuItem>
                <MenuItem onClick={this.importNav}>Import</MenuItem>
                <MenuItem onClick={this.project}>Project</MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );

  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: selectUser(state),
  }
};

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  logout,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(Header)));
