import React, { Component, Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { TwitterPicker } from 'react-color';

import purple from '@material-ui/core/colors/purple';
import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import indigo from '@material-ui/core/colors/indigo';
import blue from '@material-ui/core/colors/blue';
import cyan from '@material-ui/core/colors/cyan';
import teal from '@material-ui/core/colors/teal';
import lightGreen from '@material-ui/core/colors/lightGreen';
import yellow from '@material-ui/core/colors/yellow';
import lime from '@material-ui/core/colors/lime';
import orange from '@material-ui/core/colors/orange';
import deepOrange from '@material-ui/core/colors/deepOrange';
import brown from '@material-ui/core/colors/brown';
import blueGrey from '@material-ui/core/colors/blueGrey';

import { withStyles } from '@material-ui/core/styles';

const COLOR_INDEX = 100;
const COLORS = [
  purple[COLOR_INDEX],
  red[COLOR_INDEX],
  pink[COLOR_INDEX],
  indigo[COLOR_INDEX],
  blue[COLOR_INDEX],
  cyan[COLOR_INDEX],
  teal[COLOR_INDEX],
  yellow[COLOR_INDEX],
  lime[COLOR_INDEX],
  lightGreen[COLOR_INDEX],
  orange[COLOR_INDEX],
  deepOrange[COLOR_INDEX],
  brown[COLOR_INDEX],
  blueGrey[COLOR_INDEX],
];

const style = props => ({
  popover: {
    position: 'absolute',
    zIndex: '2',
    top: 40,
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
});

class CategoryColor extends Component {
  state = {
    displayColorPicker: false,
  };

  toggleColorPicker = () => {
    this.setState(prev => { return { displayColorPicker: !prev.displayColorPicker } })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  setColor = (color) => {
    this.setState({
      displayColorPicker: false
    });
    this.props.updateColor(color);
  };

  renderColorPicker = (color) => {
    const { classes } = this.props;
    const { displayColorPicker } = this.state;

    return (
      displayColorPicker?
        <div className={ classes.popover }>
          <div className={ classes.cover } onClick={ this.handleClose }/>
          <TwitterPicker color={ color } colors={ COLORS } onChange={ this.setColor }/>
        </div>
      : null
    );

  }

  render() {
    const { color, updateColor } = this.props;
    const currColor = color || COLORS[0];

    return (
      <Fragment>
        <Avatar style={{background: currColor}} onClick={ updateColor ? this.toggleColorPicker : null }/>
        { this.renderColorPicker(currColor) }
      </Fragment>
    );
  }
}
export default withStyles(style)(CategoryColor);
