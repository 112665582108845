import moment from 'moment';
import { CALL_API } from './middleware/api';

export const INCOMES = 'INCOMES';
export const INCOMES_TOTAL = 'INCOMES_TOTAL';
export const ADD_INCOME = 'ADD_INCOME';
export const REMOVE_INCOME = 'REMOVE_INCOME';
export const HISTORY = 'HISTORY';
export const RECURRING_INCOMES = 'RECURRING_INCOMES';
export const ADD_RECURRING_INCOME = 'ADD_RECURRING_INCOME';
export const REMOVE_RECURRING_INCOME = 'REMOVE_RECURRING_INCOME';

const getMonth = (date) => date.format("MM-YY");

export function fetchIncomes(date = moment(new Date())) {
  const month = getMonth(date);
  const dateStr = new URLSearchParams(Object.entries({ date: month }));
  return {
    [CALL_API]: {
      endpoint: 'income?' + dateStr,
      onSuccess: INCOMES,
      params: {
        month,
      }
    },
  }
}

export function fetchTotal(date = moment(new Date())) {
  const month = getMonth(date);
  const dateStr = new URLSearchParams(Object.entries({ date: month }));
  return {
    [CALL_API]: {
      endpoint: 'income/total?' + dateStr,
      onSuccess: INCOMES_TOTAL,
      params: {
        month,
      }
    },
  }
}

export function createIncome(desc, price, user, date) {
  return {
    [CALL_API]: {
      endpoint: 'income',
      onSuccess: ADD_INCOME,
      data: {desc, price, user, date: getMonth(date)},
      params: {
        month: getMonth(date),
      },
      method: 'POST',
    },
  }
}

export function deleteIncome(item) {
  return {
    [CALL_API]: {
      endpoint: 'income',
      onSuccess: REMOVE_INCOME,
      data: { id: item.id },
      params: {
        month: item.date,
      },
      method: 'DELETE',
    },
  }
}

export function fetchIncomeHistory() {
  return {
    [CALL_API]: {
      endpoint: 'income/history',
      onSuccess: HISTORY,
    },
  }
}

export function fetchRecurringIncomes() {
  return {
    [CALL_API]: {
      endpoint: 'recurringIncome',
      onSuccess: RECURRING_INCOMES,
    },
  }
}

export function createRecurringIncome(desc, price, user) {
  return {
    [CALL_API]: {
      endpoint: 'recurringIncome',
      onSuccess: ADD_RECURRING_INCOME,
      data: {desc, price, user},
      method: 'POST',
    },
  }
}

export function deleteRecurringIncome(item) {
  return {
    [CALL_API]: {
      endpoint: 'recurringIncome',
      onSuccess: REMOVE_RECURRING_INCOME,
      data: { id: item.id },
      method: 'DELETE',
    },
  }
}

export default function reducer(state = {incomes: {}, recurringIncomes: {}, total: {}, history: []}, action) {
  switch (action.type) {
    case INCOMES:
      return {
        ...state,
        incomes: {
          ...state.incomes,
          [action.month]: action.response
        },
      };
      case INCOMES_TOTAL:
        return {
          ...state,
          total: {
            ...state.total,
            [action.month]: action.response
          },
        };
      case ADD_INCOME:
        return {
          ...state,
          incomes: {
            ...state.incomes,
            [action.month]: [...(state.incomes[action.month] || []), action.response.item],
          },
        };
      case REMOVE_INCOME:
        return {
          ...state,
          incomes: {
            ...state.incomes,
            [action.month]: state.incomes[action.month].filter(inc => inc.id !== action.response.item),
          },
        };
    case HISTORY:
      return {
        ...state,
        history: Object.assign({}, ...action.response.map(item => ({[item.date]: item.total}))),
      };
    case RECURRING_INCOMES:
      return {
        ...state,
        recurringIncomes: Object.assign({}, ...action.response.map(item => ({[item.id]: item}))),
      };
      case ADD_RECURRING_INCOME:
        return {
          ...state,
          recurringIncomes: {
            ...state.recurrings,
            [action.response.item.id]: action.response.item,
          },
        };
      case REMOVE_RECURRING_INCOME:
        const recurringIncomes = {...state.recurringIncomes};
        delete recurringIncomes[action.response.item];
        return {
          ...state,
          recurringIncomes,
        };
    default:
      return state
  }
}

export const selectIncomes = (state, date) => state.incomes.incomes[getMonth(date)];
export const selectTotal = (state, date = moment()) => state.incomes.total[getMonth(date)];
export const selectRecurringIncomes = (state) => Object.values(state.incomes.recurringIncomes);
export const selectHistory = (state) => state.incomes.history;
