import moment from 'moment';
import partition from 'lodash/partition';
import { CALL_API } from './middleware/api';
import { FETCH_EXPENSES } from './middleware/expenses';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';

export const EXPENSES = 'EXPENSES';
export const ADD_EXPENSE = 'ADD_EXPENSE';
export const ADD_EXPENSES = 'ADD_EXPENSES';
export const REMOVE_EXPENSE = 'REMOVE_EXPENSE';
export const UPDATE_EXPENSE = 'UPDATE_EXPENSE';
export const UPDATE_EXPENSE_CATEGORY = 'UPDATE_EXPENSE_CATEGORY';
export const EXPENSES_HISTORY = 'EXPENSES_HISTORY';

export function fetchExpenses(date = moment(new Date())) {
  return {
    [FETCH_EXPENSES]: {
      date
    },
  }
}

export function fetchExpensesHistory() {
  return {
    [CALL_API]: {
      endpoint: 'expense/history',
      onSuccess: EXPENSES_HISTORY,
    },
  }
}

export function createExpense(desc, price, user, date, category) {
  return {
    [CALL_API]: {
      endpoint: 'expense',
      onSuccess: ADD_EXPENSE,
      data: {desc, price, user, date: moment(date).format("DD-MM-YY"), category},
      method: 'POST',
    },
  }
}

export function createExpenses(expenses) {
  return {
    [CALL_API]: {
      endpoint: 'expense/batch',
      onSuccess: ADD_EXPENSES,
      data: expenses,
      method: 'POST',
    },
  }
}

export function deleteExpense(itemId) {
  return {
    [CALL_API]: {
      endpoint: 'expense',
      onSuccess: REMOVE_EXPENSE,
      data: { id: itemId },
      method: 'DELETE',
    },
  }
}

export function updateExpense(item) {
  return {
    [CALL_API]: {
      endpoint: 'expense',
      onSuccess: UPDATE_EXPENSE,
      data: { item },
      method: 'PUT',
    },
  }
}

export function updateItemCategory(item, category) {
  if (item.categoryId === category.id) {
    return;
  }
  return {
    [CALL_API]: {
      endpoint: 'expense/category',
      onSuccess: UPDATE_EXPENSE_CATEGORY,
      data: { expenseId: item.id, categoryId: category.id },
      method: 'PUT',
      params: {
        prevCategory: item.categoryId,
      }
    },
  }
}

const startOfMonth = (date) => moment(date, "DD-MM-YY").startOf('month');
const itemMonth = (item) => startOfMonth(item.date);

export default function reducer(state = {expenses: {}, history: {}}, action) {
  switch (action.type) {
    case EXPENSES:
      return {
        ...state,
        expenses: {
          ...state.expenses,
          [startOfMonth(action.date)]: action.response,
        }
      };
    case ADD_EXPENSE:
      let item = action.response.item;
      let monthKey = itemMonth(item);
      let expensesArray = [item, ...(state.expenses[monthKey] || [])];
      return {
        ...state,
        expenses: {
                    ...state.expenses,
                    [monthKey]: expensesArray,
                  },
      };
    case ADD_EXPENSES:
      //Not worth it, just reload
      return state
    case REMOVE_EXPENSE:
      return removeItem(action.response.item, state);
    case UPDATE_EXPENSE:
      item = action.response.item;
      const newState = removeItem(item.id, state);
      const expenses = {...newState.expenses};
      const history = [...newState.history];

      monthKey = itemMonth(item);
      if (expenses[monthKey]) {
        expenses[monthKey] = [...expenses[monthKey], item];
      }

      let month = moment(item.date, "DD-MM-YY").format('YY-MM');
      let nextCat = history.find(i => i.date === month && i.category === item.categoryId);
      if (nextCat) {
        nextCat.sum += item.price;
      }

      return {
        ...state,
        expenses,
        history,
      };

    case UPDATE_EXPENSE_CATEGORY:
      item = action.response.item;
      monthKey = itemMonth(item);
      expensesArray = [...state.expenses[monthKey]];
      expensesArray[expensesArray.findIndex(i => i.id === item.id)] = item;

      month = moment(item.date, "DD-MM-YY").format('YY-MM');
      let expensesHistory = [...state.history];
      nextCat = expensesHistory.find(i => i.date === month && i.category === item.categoryId);
      if (nextCat) {
        nextCat.sum += item.price;
      }
      let prevCat = expensesHistory.find(i => i.date === month && i.category === action.prevCategory);
      if (prevCat) {
        prevCat.sum -= item.price;
      }

      return {
        ...state,
        expenses: {
                    ...state.expenses,
                    [monthKey]: expensesArray,
                  },
        history: expensesHistory,
      };
    case EXPENSES_HISTORY:
      return {
        ...state,
        history: action.response,
      };
    default:
      return state
  }
}

function removeItem(itemId, state) {
  let expenses = {...state.expenses};
  let history = [...state.history];

  const prevMonthKey = Object.keys(expenses).find(k => expenses[k].find(e => e.id === itemId));
  if (expenses[prevMonthKey]) {
    const [prevItemArray, filteredExpenses] = partition(expenses[prevMonthKey], i => (i.id === itemId));
    expenses[prevMonthKey] = filteredExpenses;
    const prevItem = prevItemArray[0];

    const prevMonth = moment(prevItem.date, "DD-MM-YY").format('YY-MM');
    let prevCat = history.find(i => i.date === prevMonth && i.category === prevItem.categoryId);
    if (prevCat) {
      prevCat.sum -= prevItem.price;
    }
  }

  return {
    ...state,
    expenses,
    history,
  };
}

export function selectExepnses(state, date = moment(new Date()).startOf('month')) {
   return state.expenses.expenses[date];
 }

export const selectAllExepnses = (state) => state.expenses.expenses;
export const selectExepnsesHistory = (state) => state.expenses.history;
export const selectTotalExpensesHistory = (state) => {
  const history = selectExepnsesHistory(state);
  const groups = groupBy(history, 'date');
  return mapValues(groups, list => list.reduce((acc, val) => acc + val.sum, 0));
}
