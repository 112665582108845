import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import { ResponsiveLine } from '@nivo/line'

import { withStyles } from '@material-ui/core/styles';

import { selectExepnsesHistory } from '../redux/expenses';
import { selectCategories } from '../redux/categories';

import grey from '@material-ui/core/colors/grey';

const styles = theme => ({
  root: {
    width: '100%',
    height: 300,
    paddingBottom: 50,
  }
});

class ExpensesGraphs extends PureComponent {

  render() {
    const { expenses, filter, classes, categories, currentMonth } =  this.props;
    if (!expenses || !expenses.length) return null;
    const groups = groupBy(expenses, 'category');
    const output = {};
    Object.keys(groups).forEach(group => {
      const groupKey = group === 'null' ? null : Number(group);
      const category = categories.find(c => c.id === groupKey);
      if (filter.includes(groupKey)) {
        output[group] = {
          id: category.name || 'Uncategorized',
          data: groups[group].map(item => ({x: item.date, y: item.sum})),
          color: category.color,
        }
      }
    });

    const data = Object.values(output);
    return (
      <div className={ classes.root }>
      <ResponsiveLine
        data={ data }
        height={ 300 }
        margin={{
            "top": 10,
            "right": 20,
            "bottom": 20,
            "left": 50,
        }}
        markers={[{
              axis: 'x',
              value: moment(currentMonth).format("MM-YY"),
              lineStyle: {stroke: '#b0413e',strokeWidth: 2},
            },
          ]}
        yScale={{
            "type": "linear",
            "stacked": false,
            "min": "auto",
            "max": "auto",
        }}
        colorBy={e => e.color}
        dotSize={10}
        dotBorderColor="#fff"
        dotBorderWidth={2}
        dotColor="inherit:darker(0.3)"
        dotLabel="y"
        dotLabelYOffset={-12}
        motionStiffness={90}
        motionDamping={15}
        />
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    expenses: selectExepnsesHistory(state),
    categories: selectCategories(state),
  };
}

export default connect(
  mapStateToProps,
)(withStyles(styles)(ExpensesGraphs));
