import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { withStyles } from '@material-ui/core/styles';
import { selectUsers } from '../redux/projects';

const itemStyle = theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  checkbox: {
    margin: theme.spacing.unit,
    '&:after': {
      paddingLeft: 2 * theme.spacing.unit,
      content: '"→"',
    },
  },
  column: {
    flex: 1,
    margin: theme.spacing.unit,
  },
  format: {
    flex: '0 1 200',
    margin: 2 * theme.spacing.unit,
  },
  hidden: {
    visibility: 'hidden',
  }
});

class MatchUserItem extends PureComponent {
  state = {}

  handleChange = event => {
    const target = event.target;
    this.setState(prev => ({...prev, [target.value]: target.checked }));
  }

  handleMappingChanged = (event) => {
    const target = event.target;
    this.props.onUserMappingChange(target.name, target.value);
  }

  render() {
    const {
      sourceUser,
      users,
      classes,
      userMapping,
      noCheckbox = false,
    } = this.props;

    const checked = noCheckbox ? true : this.state[sourceUser];

    return (
      <Grid item className={classes.row}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={this.handleChange}
              value={sourceUser}
              className={noCheckbox ? classes.hidden: null}
            />
          }
          label={sourceUser}
          className={classes.checkbox}
        />
        <TextField
          select
          required
          name={sourceUser}
          disabled={!checked}
          value={userMapping[sourceUser]}
          onChange={this.handleMappingChanged}
          className={classes.column} >
          {Object.keys(users).map(userId => (
            <MenuItem key={userId} value={userId}>
              {users[userId].name || users[userId].email}
            </MenuItem>
          ))}
       </TextField>
      </Grid>
    );
  }
}
MatchUserItem = withStyles(itemStyle)(MatchUserItem);

class MatchUsersValues extends PureComponent {
  render() {
    const {
      items,
      mapping,
      users,
      onUserMappingChange,
      userMapping,
      classes,
    } = this.props;

    const sourceUsers = items.map(item => item[mapping['user']]);
    const uniqueUsers = Array.from(new Set(sourceUsers));

    return (
      <Grid container
         direction='column'
         alignItems='center'
         className={classes.root}>
        { uniqueUsers.map(s => (
          <MatchUserItem sourceUser={s}
                         users={users}
                         onUserMappingChange={onUserMappingChange}
                         userMapping={userMapping}
                         />
         ))}

         <MatchUserItem sourceUser='default'
                        users={users}
                        onUserMappingChange={onUserMappingChange}
                        userMapping={userMapping}
                        noCheckbox
                        />
      </Grid>
    );
  }
}

const style = theme => ({
  root: {
    paddingLeft: 48,
    paddingRight: 32,
  }
});

const mapStateToProps = (state, ownProps) => {
  return {
    users: selectUsers(state),
  };
}

export default connect(
  mapStateToProps,
)(withStyles(style)(MatchUsersValues));
