import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { withStylesProps } from '../utils/utils';

const styles = props => ({
  item: {
    background: props.category ? props.category.color : null,
    '&:hover': {
      filter: 'brightness(70%)',
    }
  },
});

let RecurringListItem = ({ classes, recurring, user, category, onDelete }) => (
  <TableRow
    tabIndex={-1}
    className={classes.item}
  >
    <TableCell>{recurring.desc}</TableCell>
    <TableCell numeric>{recurring.price}</TableCell>
    <TableCell>{user ? (user.name || user.email) : ''}</TableCell>
    <TableCell numeric>
      <IconButton aria-label="Delete" onClick={() => onDelete(recurring.id)}>
        <DeleteIcon/>
      </IconButton>
    </TableCell>
  </TableRow>
);

export default withStylesProps(styles)(RecurringListItem);
