import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Snackbar from './Snackbar';
import { selectError, clearError } from '../redux/app';

const Error = ({ error, clearError }) => {
  return (
    <Snackbar
      open={ error != null }
      message={ error }
      onClose={ clearError }
      variant='error'
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    error: selectError(state),
  };
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  clearError,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Error);
