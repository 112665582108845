import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { nextMonth } from '../utils/dates';

import { selectCategories } from '../redux/categories';
import { createExpense } from '../redux/expenses';
import { selectUsers } from '../redux/projects';
import { selectUser } from '../redux/auth';

const styles = theme => ({
  root: {
    padding: 16,
  },
  row: {
    display: 'flex',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 300,
  },
  more: {
    flex: 1,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

class ExpenseForm extends Component {
   state = {
     date: moment().format('YYYY-MM-DD'),
     userId: this.props.currentUserId,
     category: {id: null},
     open: false,
     split: 1,
   };

   handleClick = () => {
     this.setState(state => ({ open: !state.open }));
   };

   handleInputChange = (event) => {
     const target = event.target;
     const name = target.name;
     const value = (name === 'category')
                     ? this.props.categories.find(i => i.id === target.value) || { id: null }
                     : target.value;

     this.setState({
       [name]: value
     });
   }

    handleSubmit = (event) => {
      event.preventDefault();
      const split = Number(this.state.split)
      const price = Math.round(Number(this.state.price) / split * 100) / 100;
      let date = moment(this.state.date);
      for (let i = 0; i < split; i++) {
        this.props.createExpense(this.state.desc, price, this.state.userId, date, this.state.category.id);
        date = nextMonth(date);
      }
      this.setState({
        date: moment().format('YYYY-MM-DD'),
        desc: '',
        category: {id: null},
        price: '',
        split: 1,
      });
    }

   render() {
     if (!this.props.categories || !this.props.users) {
       return null;
     }

     return (
       <Paper className={this.props.classes.root}>
        <form onSubmit={this.handleSubmit}>
         <Grid container
           direction="column"
           alignItems="center"
           spacing={32}>
           <Grid item>
              <TextField label="Description"
                          name="desc"
                          type="text"
                          required
                          value={this.state.desc}
                          onChange={this.handleInputChange}
                          className={this.props.classes.textField}  />
          </Grid>
          <Grid item>
            <TextField label="Price"
                    name="price"
                    type="number"
                    required
                    value={this.state.price}
                    onChange={this.handleInputChange}
                    className={this.props.classes.textField}  />
          </Grid>
          <Grid item>
            <TextField
              select
              name="category"
              label="Category"
              value={this.state.category.id ? this.state.category.id : ''}
              onChange={this.handleInputChange}
              className={this.props.classes.textField}
            >
              {this.props.categories.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item className={this.props.classes.row} onClick={this.handleClick}>
            <Typography className={this.props.classes.row}>
              More
            </Typography>
            {this.state.open
              ? <ExpandLess />
              : <ExpandMore />}
          </Grid>

          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
             <Grid container
               direction="column"
               alignItems="center"
               spacing={32}>
             <Grid item>
               <TextField label="Split"
                       name="split"
                       type="number"
                       value={this.state.split}
                       onChange={this.handleInputChange}
                       className={this.props.classes.textField}
                       />
             </Grid>
              <Grid item>
                <TextField
                  name="date"
                  label="Date"
                  type="date"
                  required
                  value={this.state.date}
                  onChange={this.handleInputChange}
                  className={this.props.classes.textField}
                />
              </Grid>
              <Grid item>
                <TextField
                  select
                  name="userId"
                  label="User"
                  required
                  value={this.state.userId}
                  onChange={this.handleInputChange}
                  className={this.props.classes.textField}
                >
                  {Object.values(this.props.users).map(option => (
                    <MenuItem key={option.id} value={Number(option.id)}>
                      {option.name || option.email}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Collapse>
          <Grid item>
            <Button variant="contained" type='submit'>Submit</Button>
          </Grid>
        </Grid>
        </form>
      </Paper>
     );
   }
}

const mapStateToProps = (state, ownProps) => {
  return {
    categories: selectCategories(state),
    users: selectUsers(state),
    currentUserId: Number(selectUser(state).id),
  };
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  createExpense,
}, dispatch);

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpenseForm));
