import React from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { selectCategory } from '../redux/categories';
import { selectUser } from '../redux/projects';

import { withStylesProps } from '../utils/utils';

const DescTableCell = ({ desc }) => <TableCell padding='dense'>{desc}</TableCell>
const PriceTableCell = ({ price }) => <TableCell padding='dense' numeric>{price}</TableCell>
const DateTableCell = ({ date }) => <TableCell padding='dense'>{date}</TableCell>
const UserTableCell = ({ user }) => <TableCell padding='dense'>{user ? (user.name || user.email) : ''}</TableCell>

const styles = props => ({
  item: {
    background: props.category ? props.category.color : null,

    '&:hover': {
      filter: 'brightness(70%)',
    }
  },
});

let ExpensesListItem = ({ classes, expense, user, connectDragSource, onClick }) => {
  const { desc, price, date } = expense;
  return (
    <TableRow
      tabIndex={-1}
      className={classes.item}
      ref={instance => connectDragSource && connectDragSource(findDOMNode(instance))}
      onClick={onClick}
    >
      <DescTableCell desc={desc} />
      <PriceTableCell price={price} />
      <DateTableCell date={date} />
      <UserTableCell user={user} />
    </TableRow>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    category: selectCategory(state, ownProps.expense.categoryId),
    user: selectUser(state, ownProps.expense.userId),
  };
}

export default connect(
  mapStateToProps,
)(withStylesProps(styles)(ExpensesListItem));
