import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import MailOutline from '@material-ui/icons/MailOutline';
import Avatar from '@material-ui/core/Avatar';

import AddUser from './AddUser';

import { withStyles } from '@material-ui/core/styles';
import { selectUsers,
         fetchCreateInvite,
         fetchRemoveUser } from '../redux/projects';

import { selectUser } from '../redux/auth';

const PeopleListItem = ({ user, onDelete }) => {
  return (
    <ListItem>
      <Avatar src={user.avatar} />
      <ListItemText primary={user.name} secondary={user.email} />
      { onDelete &&
        <ListItemSecondaryAction>
          <IconButton aria-label="Delete" onClick={() => onDelete(user)}>
            <DeleteIcon/>
          </IconButton>
        </ListItemSecondaryAction>
      }
    </ListItem>
  );
};

const InviteListItem = ({ invite, send }) => {
  return (
    <ListItem>
      <Avatar>{invite.email.charAt(0).toUpperCase()}</Avatar>
      <ListItemText primary={invite.email} />
      <ListItemSecondaryAction>
        <IconButton aria-label="mail" onClick={() => send(invite.email)}>
          <MailOutline/>
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

class Project extends PureComponent {
  onDelete = (user) => {
    this.props.fetchRemoveUser(user.id);
  }
  invite = (email) => {
    this.props.fetchCreateInvite(email);
  }
  addUser = (email) => {
    this.props.fetchCreateInvite(email);
  }

  render() {
    const { allUsers, currentUser } = this.props;

    if (!allUsers) {
      return null;
    }

    const {users, invites} = Object.entries(allUsers).reduce((res, [userId, user]) => {
      if (user.googleProviderId) {
        res.users[userId] = user;
      } else {
        res.invites[userId] = user;
      }
      return res;
    }, {users: {}, invites: {}});

    return (
      <Grid container justify="center">
        <Grid item xs={12} sm={4}>
    			<Paper className={this.props.classes.addCard}>
            <AddUser onAdd={this.addUser}/>
    			</Paper>

          <Paper className={this.props.classes.usersCard}>
  					<List>
  						{Object.values(users).map((u) => {
  							return (
					        <PeopleListItem key={u.id}
                                  user={u}
                                  onDelete={u.id !== currentUser.id ? this.onDelete : null}/>
  							);
  						})}
  					</List>
    			</Paper>

          {
            (Object.keys(invites).length > 0) && (
              <Paper>
      					<List>
      						{Object.values(invites).map((i) => {
      							return (
    					        <InviteListItem key={i.id} invite={i} send={this.invite}/>
      							);
      						})}
      					</List>
        			</Paper>
            )
          }
        </Grid>
      </Grid>
    );
  }
}

const projectStyle = props => ({
  addCard: {
    marginBottom: 32,
    paddingTop: 16,
    paddingBottom: 8,
  },
  usersCard: {
    marginBottom: 16,
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    allUsers: selectUsers(state),
    currentUser: selectUser(state),
  };
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  fetchCreateInvite,
  fetchRemoveUser,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(projectStyle)(Project));
