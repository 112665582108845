import { ERROR, MSG } from '../app';
import { LOGOUT } from '../auth';

const BASE_URL = `${process.env.REACT_APP_SERVER_URL}/api/`;

async function callApi(endpoint, data = null, method = 'GET', authenticated = true) {
  let config = {
    method: method,
    body: data && JSON.stringify(data),
    headers:{
      'Content-Type': 'application/json'
    },
  };

  const token = localStorage.getItem('jwt_token') || null;
  const project = localStorage.getItem('current_project_id') || null;
  if(authenticated) {
    if(token) {
      config = {
        ...config,
        headers: {
          ...config.headers,
          'Authorization': `Bearer ${token}`,
          'project_id': project,
        }
      };
    }
    else {
      const error = Error();
      error.message = 'No token saved!';
      error.type = LOGOUT;
      throw error;
    }
  }

  const response = await fetch(BASE_URL + endpoint, config);
  const body = await response.json();

  if (!response.ok) {
    const error = Error();
    error.message = body;
    if (response.status === 401 || response.status === 403) {
      error.type = LOGOUT;
    } else {
      error.type = ERROR;
    }
    throw error;
  }
  return body;
}

export const CALL_API = Symbol('Call API')

export default store => next => action => {
  const callAPI = action[CALL_API];

  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  let { endpoint, data, method, onSuccess, authenticated, params } = callAPI;

  // Passing the authenticated boolean back in our data will let us distinguish between normal and secret quotes
  return callApi(endpoint, data, method, authenticated).then(
    response => {
      next({
        response,
        authenticated,
        type: onSuccess,
        ...params,
      });
      if (response.msg) {
        next({
          msg: response.msg,
          type: MSG,
        });
      }
    },
    error => next({
      error: error.message || 'There was an error.',
      type: error.type || ERROR,
    })
  )
}
