import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import IncomeListItem from './IncomeListItem';

import { selectRecurringIncomes, deleteRecurringIncome } from '../redux/incomes';
import { selectUsers } from '../redux/projects';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    width: '100%',
  },
});

const columnData = [
  { id: 'desc', numeric: false, label: 'Description' },
  { id: 'userId', numeric: false, label: 'User' },
  { id: 'price', numeric: true, label: 'Price' },
  { id: 'delete', numeric: true, label: '' },
];

class RecurringIncomeTable extends PureComponent {
  onDelete = (item) => {
    this.props.deleteRecurringIncome(item);
  }

  render() {
    const { classes, recurringIncomes, users = {} } = this.props;
    if (!recurringIncomes || !recurringIncomes.length) {
      return null;
    }

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {columnData.map(column => (
                <TableCell
                  key={column.id}
                  numeric={column.numeric}
                >
                  { column.label }
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {recurringIncomes.map(income => (
              <IncomeListItem key={income.id} income={income} user={users[income.userId]} onDelete={this.onDelete} />
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    recurringIncomes: selectRecurringIncomes(state),
    users: selectUsers(state),
  };
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  deleteRecurringIncome
}, dispatch);

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(RecurringIncomeTable));
