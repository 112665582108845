import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { GoogleLogin } from "@react-oauth/google";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { createToken, selectIsAuthenticated } from "../redux/auth";

import { withStyles } from "@material-ui/core/styles";

class Login extends PureComponent {
  googleResponse = (response) => {
    this.props.createToken(response);
  };

  onFailure = (response) => {
    console.log("something went wrong");
  };

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }
    return (
      <Grid container justify="center" className={this.props.classes.root}>
        <Grid item xs={12} sm={4} className={this.props.classes.left}>
          <div className={this.props.classes.login}>
            <GoogleLogin
              onSuccess={this.googleResponse}
              onError={this.onFailure}
              useOneTap
            />
          </div>
          <Typography variant="display1" className={this.props.classes.text}>
            While we're waiting for you to login, here's a funny gif:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <img
            src="https://media2.giphy.com/media/3o85xvrIZRiHfjl06A/giphy.gif"
            alt="join"
          />
        </Grid>
      </Grid>
    );
  }
}

const style = (props) => ({
  root: {
    paddingTop: 100,
  },
  left: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
  },
  login: {
    flexGrow: 1,
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: selectIsAuthenticated(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) =>
  bindActionCreators(
    {
      createToken,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Login));
