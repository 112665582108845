import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';

import AddCategory from './AddCategory';
import CategoryListItem from './CategoryListItem';

import { withStyles } from '@material-ui/core/styles';
import { deleteCategories, selectCategories, createCategory, updateCategory } from '../redux/categories';

class EditCategories extends PureComponent {
  onDelete = (category) => {
    this.props.deleteCategories([category]);
  }
  addCategory = (color, name) => {
    this.props.createCategory(name, color);
  }
  onUpdate = (id, name, color) => {
    this.props.updateCategory(id, name, color);
  }

  render() {
    const { categories } = this.props;

    return (
      <Grid container justify="center">
        <Grid item xs={12} sm={4}>
    			<Paper className={this.props.classes.addCard}>
            <AddCategory onAdd={this.addCategory}/>
    			</Paper>

    			<Paper>
  					<List>
  						{categories.map((c) => {
  							return (
					        <CategoryListItem key={c.id} category={c} onDelete={this.onDelete} onUpdate={this.onUpdate}/>
  							);
  						})}
  					</List>
    			</Paper>
        </Grid>
      </Grid>
    );
  }
}

const editStyle = props => ({
  addCard: {
    marginBottom: 32,
    paddingTop: 16,
    paddingBottom: 16,
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    categories: selectCategories(state),
  };
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  deleteCategories,
  createCategory,
  updateCategory,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(editStyle)(EditCategories));
