const asc = (orderBy, val = 1) => (a, b) => {
  if(a[orderBy] < b[orderBy]) return -1 * val;
  if(a[orderBy] > b[orderBy]) return val;
  if(a['id'] < b['id']) return -1 * val;
  return val;
}

const desc = orderBy => asc(orderBy, -1)

export function getSorting(order, orderBy) {
  return order === 'asc' ? asc(orderBy) : desc(orderBy);
}

export const columnData = [
  { id: 'desc', numeric: false, disablePadding: true, label: 'Description' },
  { id: 'price', numeric: true, disablePadding: false, label: 'Price' },
  { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
  { id: 'userId', numeric: false, disablePadding: false, label: 'User' },
];
