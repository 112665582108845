import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';

import ExpensesHistory from './ExpensesHistory';
import ExpensesGraphs from './ExpensesGraphs';
import MonthsPager from './MonthsPager';
import ExpensesPie from './ExpensesPie';
import UsersPie from './UsersPie';
import CategoriesFilter from './CategoriesFilter';
import BalanceGraph from './BalanceGraph';

import { selectCategories } from '../redux/categories';
import { fetchExpenses, fetchExpensesHistory, updateItemCategory } from '../redux/expenses';
import { fetchIncomeHistory } from '../redux/incomes';

import { nextMonth, prevMonth } from '../utils/dates';

class ExploreView extends PureComponent {
  state = {
    filter: this.props.categories.map(c => c.id),
    selectedMonth: moment(new Date()).startOf('month'),
  };

  componentDidMount() {
    this.props.fetchExpenses(this.state.selectedMonth, true);
    this.props.fetchExpensesHistory();
    this.props.fetchIncomeHistory();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.categories) !== JSON.stringify(prevProps.categories)) {
      this.setState({
        filter: this.props.categories.map(c => c.id),
      });
    }
  }

  selectPrevMonth = () => {
    this.setState( (prev) => {
      return {
        selectedMonth: prevMonth(prev.selectedMonth),
      };
    }, () => {
      this.props.fetchExpenses(this.state.selectedMonth, true);
    });
  }
  selectNextMonth = () => {
    this.setState( (prev) => {
      return {
        selectedMonth: nextMonth(prev.selectedMonth),
      };
    }, () => {
      this.props.fetchExpenses(this.state.selectedMonth, true);
    });
  }

  addFilter = (categoryId) => {
    this.setState((prev) => {
      return {
        filter: [...prev.filter, categoryId],
      };
    });
  }

  removeFilter = (categoryId) => {
    this.setState((prev) => {
      let filter = prev.filter.filter(f => f !== categoryId);
      if (filter.length == 0) {
        filter = this.props.categories.map(c => c.id);
      }
      return {
        filter: filter,
      };
    });
  }

  onCategoryClick = (categoryId) => {
    this.setState((prev) => {
      return {
        filter: [categoryId],
      };
    });
  }

  render() {
    const {
      filter,
      selectedMonth,
    } = this.state;

    return (
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <BalanceGraph />
        </Grid>
        <Grid item xs={12}>
          <MonthsPager handleBackButtonClick={this.selectPrevMonth}
                       currentMonth={selectedMonth}
                       handleNextButtonClick={this.selectNextMonth}
                       />
           <CategoriesFilter filter={filter}
                             addFilter={this.addFilter}
                             removeFilter={this.removeFilter}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ExpensesHistory selectedMonth={selectedMonth}
                           filter={filter}
                           updateItemCategory={this.props.updateItemCategory}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ExpensesGraphs currentMonth={selectedMonth}
                          filter={filter}/>
          <ExpensesPie selectedMonth={selectedMonth} filter={filter} onCategoryClick={this.onCategoryClick}/>
          <UsersPie selectedMonth={selectedMonth} filter={filter}/>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    categories: selectCategories(state),
  };
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  fetchExpenses,
  fetchExpensesHistory,
  updateItemCategory,
  fetchIncomeHistory,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DragDropContext(HTML5Backend)(ExploreView));
