import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import store from "./redux/index";
import Header from "./Header";
import ExpensesView from "./manage/ExpensesView";
import ExploreView from "./explore/ExploreView";
import EditCategories from "./categories/EditCategories";
import RecurringView from "./recurring/RecurringView";
import IncomeView from "./incomes/IncomeView";
import Project from "./project/Project";
import Login from "./login/Login";
import ErrorSnackbar from "./utils/ErrorSnackbar";
import MsgSnackbar from "./utils/MsgSnackbar";
import Import from "./import/Import";

import "./App.css";

import { fetchCategories } from "./redux/categories";
import { fetchUsers } from "./redux/projects";
import { fetchProjects } from "./redux/projects";
import { selectIsAuthenticated } from "./redux/auth";

const styles = (theme) => ({
  root: {
    margin: theme.spacing.unit * 3,
  },
});

let Content = ({ classes }) => {
  return (
    <div>
      <div className="mui--appbar-height"></div>
      <br />
      <div className={classes.root}>
        <Route exact path="/" component={ExpensesView} />
        <Route path="/explore" component={ExploreView} />
        <Route path="/categories" component={EditCategories} />
        <Route path="/project" component={Project} />
        <Route path="/import" component={Import} />
        <Route path="/recurring" component={RecurringView} />
        <Route path="/incomes" component={IncomeView} />
      </div>
    </div>
  );
};

Content = withStyles(styles)(Content);

class AppContainer extends Component {
  componentDidMount() {
    this.props.fetchProjects();
    this.props.fetchCategories();
    this.props.fetchUsers();
  }

  render() {
    return (
      <div>
        <Header />
        <ErrorSnackbar />
        <MsgSnackbar />
        <Content />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) =>
  bindActionCreators(
    {
      fetchCategories,
      fetchUsers,
      fetchProjects,
    },
    dispatch
  );

AppContainer = withRouter(connect(null, mapDispatchToProps)(AppContainer));

class PrivateRoute extends Component {
  render() {
    const { component: Component, isAuthenticated, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated === true ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: selectIsAuthenticated(state),
  };
};

PrivateRoute = withRouter(connect(mapStateToProps)(PrivateRoute));

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <GoogleOAuthProvider clientId="923047239555-ifkl05a9pupp9075epo7ur9hqp0rji77.apps.googleusercontent.com">
          <Router>
            <Switch>
              <Route exact path="/login" component={Login} />
              <PrivateRoute component={AppContainer} />
            </Switch>
          </Router>
        </GoogleOAuthProvider>
      </Provider>
    );
  }
}

export default App;
