import { CALL_API } from './middleware/api';

export const PROJECTS = 'PROJECTS';
export const USERS = 'USERS';
export const NEW_INVITE = 'NEW_INVITE';
export const REMOVE_USER = 'REMOVE_USER';

export function fetchProjects() {
  return {
    [CALL_API]: {
      endpoint: 'project',
      onSuccess: PROJECTS,
    },
  }
}

export function fetchUsers() {
  return {
    [CALL_API]: {
      endpoint: 'project/user',
      onSuccess: USERS,
    },
  }
}

export function fetchCreateInvite(email) {
  return {
    [CALL_API]: {
      endpoint: 'project/invite',
      onSuccess: NEW_INVITE,
      data: { email },
      method: 'POST',
    },
  }
}

export function fetchRemoveUser(id) {
  return {
    [CALL_API]: {
      endpoint: `project/user/${id}`,
      onSuccess: REMOVE_USER,
      method: 'DELETE',
    },
  }
}

export default function reducer(state = {projects: {}}, action) {
  switch (action.type) {
    case PROJECTS:
      return {
        ...state,
        projects: Object.assign({}, ...action.response.map(item => ({[item.id]: item}))),
      };
    case USERS:
      return {
        ...state,
        users: Object.assign({}, ...action.response.map(item => ({[item.id]: item}))),
      };
    case REMOVE_USER:
      const users = {...state.users};
      delete users[action.response.id];
      return {
        ...state,
        users,
      };
    case NEW_INVITE:
      return {
        ...state,
        users: {...state.users, [action.response.id]: action.response},
      };
    default:
      return state
  }
}

export const selectProjects = (state) => { return state.projects.projects }
export const selectProject = (state, projectId) => { return state.projects.projects[projectId] }

export const selectUsers = (state) => { return state.projects.users }
export const selectUser = (state, userId) => { return state.projects.users ? state.projects.users[userId] : null }

export const selectInvites = (state) => { return state.projects.invites }
