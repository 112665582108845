import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ExpenseForm from './ExpenseForm';
import ExpensesExplorer from '../expenses_list/ExpensesExplorer';
import ExpensesListItem from '../expenses_list/ExpensesListItem';
import EditExpensesListItem from '../expenses_list/EditExpensesListItem';
import ExpensesTotal from './ExpensesTotal';
import { withStyles } from '@material-ui/core/styles';

import { fetchExpenses, selectExepnses } from '../redux/expenses';

const styles = theme => ({
});

class ExpensesView extends PureComponent {
   componentDidMount() {
     this.props.fetchExpenses();
   }

  render() {
    return (
      <Grid container spacing={24}>
        <Grid item xs={12} sm={4}>
          <ExpenseForm />
          <ExpensesTotal />
        </Grid>
        <Grid item xs={12} sm={8}>
          <ExpensesExplorer expenses={this.props.expenses} renderItem={
            (expense) => (
              <EditExpensesListItem key={`edit_item_${expense.id}`} expense={expense} render={
                  (expense) => (
                    <ExpensesListItem key={`item_${expense.id}`}
                                             expense={expense} />
              )}/>
            )}/>
        </Grid>
      </Grid>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    expenses: selectExepnses(state),
  };
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  fetchExpenses,
}, dispatch);

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpensesView));
