import { CALL_API } from './middleware/api';

export const RECURRINGS = 'RECURRINGS';
export const ADD_RECURRING = 'ADD_RECURRING';
export const REMOVE_RECURRING = 'REMOVE_RECURRING';

export function fetchRecurrings() {
  return {
    [CALL_API]: {
      endpoint: 'recurring',
      onSuccess: RECURRINGS,
    },
  }
}

export function createRecurring(desc, price, user, category) {
  return {
    [CALL_API]: {
      endpoint: 'recurring',
      onSuccess: ADD_RECURRING,
      data: {desc, price, user, category},
      method: 'POST',
    },
  }
}

export function deleteRecurring(itemId) {
  return {
    [CALL_API]: {
      endpoint: 'recurring',
      onSuccess: REMOVE_RECURRING,
      data: { id: itemId },
      method: 'DELETE',
    },
  }
}

export default function reducer(state = {recurrings: {}}, action) {
  switch (action.type) {
    case RECURRINGS:
      return {
        ...state,
        recurrings: Object.assign({}, ...action.response.map(item => ({[item.id]: item}))),
      };
      case ADD_RECURRING:
        return {
          ...state,
          recurrings: {
            ...state.recurrings,
            [action.response.item.id]: action.response.item,
          },
        };
      case REMOVE_RECURRING:
        const recurrings = {...state.recurrings};
        delete recurrings[action.response.item];
        return {
          ...state,
          recurrings,
        };
    default:
      return state
  }
}

export const selectRecurrings = (state) => Object.values(state.recurrings.recurrings);
