import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { ResponsiveBar } from '@nivo/bar';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { prevMonth } from '../utils/dates';

import { selectHistory } from '../redux/incomes';
import { selectTotalExpensesHistory } from '../redux/expenses';

const styles = theme => ({
  bar: {
    height: 300,
  },
  pos: {
    color: green[300],
  },
  neg: {
    color: red[400],
  }
});

const formatNumber = (number) => (number > 0 ? '+' : '') + number;

class BalanceGraph extends PureComponent {
  render() {
    const {
      incomes,
      expenses,
      classes,
    } = this.props;

    if (!incomes || !expenses) {
      return null;
    }

    let date = moment();
    let data = [];
    const balance = {};
    for (let i = 0; i < 12; i++) {
      date = prevMonth(date);
      const key = date.format('YY-MM');
      const inc = incomes[key] || 0;
      const exp = expenses[key] || 0;
      data = [{
        id: key,
        income: inc,
        expense: exp,
      }, ...data];
      balance[key] = inc - exp;
    }
    const totalBalance = Object.values(balance).reduce((acc, val) => acc + val, 0);

    return (
      <Grid container spacing={24} alignItems='center'>
        <Grid item sm={10} xs={12} className={classes.bar}>
          <ResponsiveBar
            data={data}
            keys={[
                'income',
                'expense',
            ]}
            margin={{
                'top': 30,
                'right': 10,
                'bottom': 30,
                'left': 50,
            }}
            enableLabel={false}
            groupMode='grouped'
            colors={[green[300], red[400]]}
            animate={true}
            axisTop={{
              format: e => formatNumber(balance[e]),
            }}
        />
      </Grid>
      <Grid item sm={2} xs={12}>
        <Typography variant="title" component="span">
          Total last 12 months:
        </Typography>
        <Typography variant="headline" component="span" className={totalBalance > 0 ? classes.pos : classes.neg}>
          {formatNumber(totalBalance)}
        </Typography>
      </Grid>
    </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    expenses: selectTotalExpensesHistory(state),
    incomes: selectHistory(state),
  };
}

export default connect(
  mapStateToProps,
)(withStyles(styles)(BalanceGraph));
