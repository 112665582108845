import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import EnhancedTableHead from './EnhancedTableHead';
import { getSorting } from './utils';

const styles = theme => ({
  root: {
    width: '100%',
  },
});

class ExpensesExplorer extends Component {
  state = {
      order: 'desc',
      orderBy: 'date',
    };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  render() {
    const { classes, expenses } = this.props;
    const { order, orderBy } = this.state;

    if (!expenses) {
      return null;
    }

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={expenses.length}
            />
            <TableBody>
              {expenses
                .sort(getSorting(order, orderBy))
                .map(expense => this.props.renderItem(expense))}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(ExpensesExplorer);
