import { CALL_API } from './middleware/api';

import grey from '@material-ui/core/colors/grey';

export const CATEGORIES = 'CATEGORIES';
export const REMOVE_CATEGORIES = 'REMOVE_CATEGORIES';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';

export function fetchCategories() {
  return {
    [CALL_API]: {
      endpoint: 'category',
      onSuccess: CATEGORIES,
    },
  }
}

export function deleteCategories(items) {
  return {
    [CALL_API]: {
      endpoint: 'category',
      onSuccess: REMOVE_CATEGORIES,
      method: 'DELETE',
      data: {ids: items},
    },
  }
}

export function createCategory(name, color) {
  return {
    [CALL_API]: {
      endpoint: 'category',
      onSuccess: ADD_CATEGORY,
      method: 'POST',
      data: {name, color},
    },
  }
}

export function updateCategory(id, name, color) {
  return {
    [CALL_API]: {
      endpoint: 'category',
      onSuccess: UPDATE_CATEGORY,
      method: 'PUT',
      data: {id, name, color},
    },
  }
}

const uncategory = {id: null, color: grey[100]}

export default function reducer(state = {categories: {}}, action) {
  switch (action.type) {
    case CATEGORIES:
      return {
        ...state,
        categories: Object.assign({[uncategory.id]: uncategory}, ...action.response.map(item => ({[item.id]: item}))),
      };
    case REMOVE_CATEGORIES:
      const categories = {...state.categories};
      action.response.items.forEach(k => delete categories[k]);
      return {
        ...state,
        categories,
      };
    case ADD_CATEGORY:
      return {
        ...state,
        categories: {
          ...state.categories,
          [action.response.item.id]: action.response.item,
        },
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        categories: {
          ...state.categories,
          [action.response.item.id]: action.response.item,
        },
      };
    default:
      return state
  }
}

export const selectCategories = (state) => { return Object.values(state.categories.categories) }
export const selectCategoriesMap = (state) => { return state.categories.categories }
export const selectCategory = (state, categoryId) => { return state.categories.categories[categoryId] }
