import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import RecurringForm from './RecurringForm';
import RecurringTable from './RecurringTable';
import { withStyles } from '@material-ui/core/styles';

import { fetchRecurrings } from '../redux/recurrings';

const styles = theme => ({
});

class RecurringView extends PureComponent {
   componentDidMount() {
     this.props.fetchRecurrings();
   }

  render() {
    return (
      <Grid container spacing={24}>
        <Grid item xs={12} sm={4}>
          <RecurringForm />
        </Grid>
        <Grid item xs={12} sm={8}>
          <RecurringTable />
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  fetchRecurrings,
}, dispatch);

export default withStyles(styles)(connect(
  null,
  mapDispatchToProps
)(RecurringView));
