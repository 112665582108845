import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

import CategoryColor from './CategoryColor';

import { withStyles } from '@material-ui/core/styles';
import { deleteCategories, selectCategories, createCategory, updateCategory } from '../redux/categories';

class CategoryListItem extends PureComponent {
  state = {
    editMode: false,
  }

  onUpdateColor = (color) => {
    this.props.onUpdate(this.props.category.id, this.props.category.name, color.hex);
  }

  onUpdateName = (color) => {
    this.props.onUpdate(this.props.category.id, this.state.name, this.props.category.color);
    this.toggleEdit();
  }

  onDelete = () => {
    this.props.onDelete(this.props.category.id);
  }

  toggleEdit = () => {
    this.setState(prev => ({...prev, editMode: !prev.editMode}));
  }

  edit = () => {
    this.setState({editMode: true, name: this.props.category.name});
  }

  onChangeName = (event) => {
    const target = event.target;
    this.setState({name: target.value});
  }


  renderText() {
    const { category, classes } = this.props;
    const onClick = category.id ? this.edit : null;

    return (
      this.state.editMode
        ? (
            <ListItemText disableTypography={true}>
               <TextField name="name"
                          type="text"
                          required
                          value={this.state.name}
                          onChange={this.onChangeName}
                          className={classes.editText}/>
            </ListItemText>
          )
        : <ListItemText primary={category.name || 'Uncategorize'} onClick={onClick}/>
      );
  }

  renderActions() {
    return (
      this.state.editMode
        ? (
          <ListItemSecondaryAction>
            <IconButton aria-label="Cancel" onClick={this.toggleEdit}>
              <ClearIcon/>
            </IconButton>
            <IconButton aria-label="Update" onClick={this.onUpdateName}>
              <DoneIcon/>
            </IconButton>
          </ListItemSecondaryAction>
        )
        : (
          <ListItemSecondaryAction>
            <IconButton aria-label="Delete" onClick={this.onDelete}>
              <DeleteIcon/>
            </IconButton>
          </ListItemSecondaryAction>
        )
      );
  }

  render() {
    const { category } = this.props;

    const editableCategory = category.id !== null;
    const updateColor = editableCategory ? this.onUpdateColor : null;

    return (
      <ListItem>
        <CategoryColor color={category.color} updateColor={updateColor}/>
        { this.renderText() }
        { editableCategory && this.renderActions() }
      </ListItem>
    );
  }
}

const editStyle = props => ({
  editText: {
    width: '100%',
    boxSizing: 'border-box',
    paddingRight: 64,
  }
});

const mapStateToProps = (state, ownProps) => {
  return {
    categories: selectCategories(state),
  };
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  deleteCategories,
  createCategory,
  updateCategory,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(editStyle)(CategoryListItem));
