import { EXPENSES, selectExepnses } from '../expenses';
import { CALL_API } from './api';
import { nextMonth, prevMonth } from '../../utils/dates';

export const FETCH_EXPENSES = Symbol('Fetch Expenses')

function callApi(date) {
  const urlParams = new URLSearchParams(Object.entries({ date: date.format("DD-MM-YY") }));
  return {
    [CALL_API]: {
      endpoint: 'expense?' + urlParams,
      onSuccess: EXPENSES,
      params: {
        date: date.format("DD-MM-YY"),
      }
    },
  }
}

export default store => next => action => {
  const expenses = action[FETCH_EXPENSES];

  // So the middleware doesn't get applied to every single action
  if (typeof expenses === 'undefined') {
    return next(action);
  }

  const { date } = expenses;
  if (selectExepnses(store.getState(), date) != null) {
    next(callApi(prevMonth(date)));
    next(callApi(nextMonth(date)));
  } else {
    next(callApi(date));
    next(callApi(prevMonth(date)));
    next(callApi(nextMonth(date)));
  }
}
