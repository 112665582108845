import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import { selectExepnses } from '../redux/expenses';
import { selectTotal, fetchTotal } from '../redux/incomes';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
  root: {
    marginTop: 32,
    padding:16,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
      paddingBottom: 4,
  },
  divider: {
    marginBottom: 8,
  },
  pos: {
    color: green[300],
  },
  neg: {
    color: red[400],
  }
});

class ExpensesTotal extends PureComponent {
  componentDidMount() {
    this.props.fetchTotal();
  }

  render() {
    const { expenses, income, classes} = this.props;
    if (income === undefined) {
      return null;
    }
    const balance = income - expenses;
    return (
      <Paper className={classes.root}>
        <div className={classes.row}>
          <Typography variant="headline" component="span">
            Expenses:
          </Typography>
          <Typography variant="headline" component="span">
            -{expenses}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="headline" component="span" gutterBottom>
            Incomes:
          </Typography>
          <Typography variant="headline" component="span" align="right">
            +{income}
          </Typography>
        </div>
        <Divider variant='inset' className={classes.divider}/>
        <Typography variant="display1" align="right" className={balance > 0 ? classes.pos : classes.neg}>
          {balance}
        </Typography>
     </Paper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    expenses: (selectExepnses(state) || []).reduce((a, e) => a + e.price, 0),
    income: selectTotal(state),
  };
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  fetchTotal,
}, dispatch);

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpensesTotal));
