import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';

const style = props => ({
  container: {
    display: 'flex',
    paddingLeft: 16,
  },
  textContainer: {
    flex: '1 1 auto',
    minWidth: 0,
    paddingRight: 16,
    paddingLeft: 16,
  },
  text: {
    width: '100%',
  },
  action: {
    paddingRight: 4,
  },
});

class AddUser extends Component {
  state = {
    name: '',
  };

   handleSubmit = (event) => {
     event.preventDefault();
     this.props.onAdd(this.state.name);

     this.setState({
       name: '',
     });
   }

   handleInputChange = (event) => {
     const name = event.target.name;
     const value = event.target.value;

     this.setState({
       [name]: value
     });
   }

  render() {
    const { classes } = this.props;
    const { name } = this.state;

    return (
      <form onSubmit={this.handleSubmit} >
        <div className={classes.container} >
            <div className={classes.textContainer} >
              <TextField name='name'
                         type='email'
                         required={true}
                         value={name}
                         onChange={this.handleInputChange}
                         className={classes.text}  />
            </div>
            <div className={classes.action}  >
              <IconButton aria-label='Add' type='submit'>
                <AddIcon/>
              </IconButton>
            </div>
        </div>
      </form>
    );
  }
}
export default withStyles(style)(AddUser);
