export const ERROR = 'ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const MSG = 'MSG';
export const CLEAR_MSG = 'CLEAR_MSG';

export const CURRENT_PROJECT = 'CURRENT_PROJECT';

export function onError(error) {
  return { type: ERROR, error: error.message }
}

export function clearError() {
  return { type: CLEAR_ERROR }
}

export function onMsg(msg) {
  return { type: MSG, msg }
}

export function clearMsg() {
  return { type: CLEAR_MSG }
}

export function setCurrentProject(projectId) {
  return { type: CURRENT_PROJECT, projectId }
}

const initState = {
  error: null,
  porjectId: localStorage.getItem('current_project_id')
}
export default function reducer(state = initState, action) {
  switch (action.type) {
    case ERROR:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case MSG:
      return {
        ...state,
        msg: action.msg,
      };
    case CLEAR_MSG:
      return {
        ...state,
        msg: null,
      };
    case CURRENT_PROJECT:
      return {
        ...state,
        porjectId: action.projectId,
      };
    default:
      return state
  }
}

export function selectError(state) {return state.app.error;}
export function selectMsg(state) {return state.app.msg;}
export function selectCurrentProject(state) {return state.app.porjectId;}
