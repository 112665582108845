import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import XLSX from 'xlsx';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import lightBlue from '@material-ui/core/colors/lightBlue';

import { withStyles } from '@material-ui/core/styles';

class SelectFile extends PureComponent {
  onDrop = (acceptedFiles, rejectedFiles) => {
    acceptedFiles.forEach(file => {
  		const reader = new FileReader();
  		const rABS = !!reader.readAsBinaryString;
  		reader.onload = (e) => {
  			const bstr = e.target.result;
  			const wb = XLSX.read(bstr, {type:rABS ? 'binary' : 'array'});
  			const wsname = wb.SheetNames[0];
  			const ws = wb.Sheets[wsname];
  			const data = XLSX.utils.sheet_to_json(ws, {header:'A', blankrows: false});
        const [cols, ...items] = data;
  			this.props.onDataLoad(cols, items);
  		};
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');

      if(rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
    });
  }

  render() {
    return (
      <Paper>
        <Dropzone
          accept='application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          onDrop={this.onDrop}
          className={this.props.classes.dropzone}
          activeClassName={this.props.classes.dropzone_active}>
            <Typography variant='button'> Select File </Typography>
            <Typography variant='caption'> Or drag it here </Typography>
        </Dropzone>
      </Paper>
    );
  }
}


const style = props => ({
  dropzone: {
    width: '100%',
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  dropzone_active: {
    background: lightBlue[50],
  },
});

export default withStyles(style)(SelectFile);
