import React, { Component } from 'react';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LeftArrow from '@material-ui/icons/ChevronLeft';
import RightArrow from '@material-ui/icons/ChevronRight';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    alignItems: 'center',
  },
  caption: {
    width: 200,
  },
});

class MonthsPager extends Component {

  label = () => {
    return moment(this.props.currentMonth).format("MMM YYYY");
  }

  render() {
    return (
      <Grid container justify="center" spacing={32} className={this.props.classes.root}>
        <Grid item>
          <IconButton
            onClick={this.props.handleBackButtonClick}
          >
            <LeftArrow />
          </IconButton>
        </Grid>

        <Grid item>
          <Typography variant="headline" align="center" className={this.props.classes.caption}>
              {this.label()}
          </Typography>
        </Grid>

        <Grid item>
          <IconButton
            onClick={this.props.handleNextButtonClick}
          >
            <RightArrow />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(MonthsPager);
