import React, { PureComponent } from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const itemStyle = theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    width: '100%',
    paddingBottom: theme.spacing.unit,
  },
  label: {
    margin: theme.spacing.unit,
    '&:after': {
      paddingLeft: 2 * theme.spacing.unit,
      content: '"→"',
    },
  },
  column: {
    flex: 1,
    margin: theme.spacing.unit,
  },
  format: {
    margin: theme.spacing.unit,
  },
});

class FieldItem extends PureComponent {
  handleMappingChanged = (event) => {
    const target = event.target;
    const value = Object.keys(this.props.columns).find(k => this.props.columns[k] === target.value)

    this.props.onMappingChange(target.name, value);
  }

  handleFormatChange = (event) => {
    const target = event.target;

    this.props.onMappingChange(target.name, target.value);
  }

  render() {
    const {
      label,
      name,
      columns,
      mapping,
      required,
      withFormat = false,
      classes
    } = this.props;

    return (
      <Grid item className={classes.row}>
           <Typography variant='subtitle1'
                       className={classes.label} >
               {required && '* '}{label}
           </Typography>
           <TextField
             select
             name={name}
             required
             value={columns[mapping[name]]}
             onChange={this.handleMappingChanged}
             className={classes.column}>
             <MenuItem/>
             {Object.keys(columns).map(k => (
               <MenuItem key={k} value={columns[k]}>
                 {columns[k]}
               </MenuItem>
             ))}
          </TextField>
          { withFormat && <TextField label='format'
                     name={`${name}_format`}
                     placeholder='DD/MM/YY'
                     type='text'
                     value={mapping[`${name}_format`]}
                     onChange={this.handleFormatChange}
                     className={classes.format}
                      InputLabelProps={{
                        shrink: true,
                      }} />
          }
       </Grid>
    );
  }
}
FieldItem = withStyles(itemStyle)(FieldItem);

class MatchFields extends PureComponent {
  state = {
    date: '',
    desc: '',
    price: '',
    user: '',
    category: '',
  };

  render() {
    const {
      columns,
      mapping = {},
      onMappingChange,
      classes,
    } = this.props;

    return (
     <Grid container
       direction='column'
       alignItems='center'
       className={classes.root}>

          <FieldItem label='Date'
                     name='date'
                     required
                     columns={columns}
                     onMappingChange={onMappingChange}
                     mapping={mapping}
                     withFormat />

          <FieldItem label='Price'
                     name='price'
                     required
                     columns={columns}
                     onMappingChange={onMappingChange}
                     mapping={mapping} />

          {/*<FieldItem label='Category'
                     name='category'
                     required
                     columns={columns}
                     onMappingChange={onMappingChange}
                     mapping={mapping} />*/}

          <FieldItem label='Description'
                     name='desc'
                     columns={columns}
                     onMappingChange={onMappingChange}
                     mapping={mapping} />

          <FieldItem label='User'
                     name='user'
                     columns={columns}
                     onMappingChange={onMappingChange}
                     mapping={mapping} />
      </Grid>
    );
  }
}

const style = theme => ({
  root: {
  }
});

export default withStyles(style)(MatchFields);
