import React, { Component, cloneElement } from 'react';

import EditExpenseDialog from './EditExpenseDialog';

export default class EditExpensesListItem extends Component {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { expense, render, ...otherProps } = this.props;

    return (
      [
        cloneElement(render(expense), {onClick: this.handleOpen, ...otherProps}),
        <EditExpenseDialog
          key={expense.id}
          open={this.state.open}
          onClose={this.handleClose}
          expense={expense}/>
      ]
    );
  }
}
