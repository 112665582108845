import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { DragSource } from 'react-dnd';
import ExpensesExplorer from '../expenses_list/ExpensesExplorer';
import ExpensesListItem from '../expenses_list/ExpensesListItem';
import EditExpensesListItem from '../expenses_list/EditExpensesListItem';

import { selectExepnses } from '../redux/expenses';

const itemSource = {
  beginDrag({expense}) {
    return {expense: expense.id};
  },

  endDrag(props, monitor) {
    if (!monitor.getDropResult()) {
      return;
    }
    props.onDrop(props.expense, monitor.getDropResult().category);
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}
const DragableListItem = DragSource('item', itemSource, collect)(ExpensesListItem);

class ExpensesHistory extends PureComponent {
  render() {
    return (
      <ExpensesExplorer expenses={this.props.expenses} renderItem={
        (expense) => (
          <EditExpensesListItem key={`edit_item_${expense.id}`} expense={expense} render={
              (expense) => (
                <DragableListItem key={`item_${expense.id}`}
                                  expense={expense}
                                  onDrop={this.props.updateItemCategory}  />
        )}/>
      )}/>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    expenses: (selectExepnses(state, ownProps.selectedMonth) || []).filter(
      e =>  ownProps.filter.includes(e.categoryId)),
  };
}

export default connect(
  mapStateToProps,
)(ExpensesHistory);
