import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { selectUsers } from '../redux/projects';
import { selectUser } from '../redux/auth';
import { createRecurringIncome, createIncome } from '../redux/incomes';

const styles = theme => ({
  root: {
    padding: 16,
  },
  row: {
    display: 'flex',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 300,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

class IncomeForm extends Component {
   state = {
     userId: this.props.currentUserId,
     recurrent: false,
     date: moment().format('YYYY-MM'),
   };

   handleInputChange = (event) => {
     const target = event.target;
     const name = target.name;
     const value = name === 'recurrent' ? target.checked : target.value;

     this.setState({
       [name]: value
     });
   }

    handleSubmit = (event) => {
      event.preventDefault();
      const price = Number(this.state.price);
      const date = moment(this.state.date);
      this.props.createIncome(this.state.desc, price, this.state.userId, date);

      if (this.state.recurrent) {
        this.props.createRecurringIncome(this.state.desc, price, this.state.userId);
      }
    }

   render() {
     if (!this.props.users) {
       return null;
     }

     return (
       <Paper className={this.props.classes.root}>
        <form onSubmit={this.handleSubmit}>
         <Grid container
           direction="column"
           alignItems="center"
           spacing={32}>
           <Grid item>
            <TextField label="Description"
                        name="desc"
                        type="text"
                        required
                        value={this.state.desc}
                        onChange={this.handleInputChange}
                        className={this.props.classes.textField}  />
          </Grid>
          <Grid item>
            <TextField label="Price"
                    name="price"
                    type="number"
                    required
                    value={this.state.price}
                    onChange={this.handleInputChange}
                    className={this.props.classes.textField}  />
          </Grid>
          <Grid item>
            <TextField
              select
              name="userId"
              label="User"
              required
              value={this.state.userId}
              onChange={this.handleInputChange}
              className={this.props.classes.textField}
            >
              {Object.values(this.props.users).map(option => (
                <MenuItem key={option.id} value={Number(option.id)}>
                  {option.name || option.email}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              name="date"
              label="Month"
              type="month"
              required
              value={this.state.date}
              onChange={this.handleInputChange}
              className={this.props.classes.textField}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                 <Checkbox name="recurrent"
                           checked={this.state.recurring}
                           onChange={this.handleInputChange} />
              }
              label='Recurring'
            />
          </Grid>
          <Grid item>
            <Button variant="contained" type='submit'>Submit</Button>
          </Grid>
        </Grid>
        </form>
      </Paper>
     );
   }
}

const mapStateToProps = (state, ownProps) => {
  return {
    users: selectUsers(state),
    currentUserId: Number(selectUser(state).id),
  };
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  createIncome,
  createRecurringIncome,
}, dispatch);

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(IncomeForm));
