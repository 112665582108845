import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import app from './app';
import categories from './categories';
import expenses from './expenses';
import auth from './auth';
import projects from './projects';
import recurrings from './recurrings';
import incomes from './incomes';
import api from './middleware/api';
import expensesMid from './middleware/expenses';

const rootReducer = combineReducers({
  app,
  categories,
  expenses,
  auth,
  projects,
  recurrings,
  incomes,
});

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(
    thunkMiddleware, // lets us dispatch() functions
    expensesMid,
    api,
  )
)

export default store;
