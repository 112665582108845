import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Snackbar from './Snackbar';
import { selectMsg, clearMsg } from '../redux/app';

const Msg = ({ msg, clearMsg }) => {
  return (
    <Snackbar
      open={ msg != null }
      message={ msg }
      onClose={ clearMsg }
      variant='success'
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    msg: selectMsg(state),
  };
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  clearMsg,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Msg);
