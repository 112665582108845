import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';

import { createExpenses } from '../redux/expenses';

import { withStyles } from '@material-ui/core/styles';

class ImportButton extends PureComponent {
  createExpenses = () => {
    this.props.createExpenses(this.props.expenses);
  }

  render() {
    return (
      <Button variant="contained"
              color="primary"
              className={this.props.classes.root}
              onClick={this.createExpenses}>
        Add {this.props.expenses.length} items
      </Button>
    );
  }
}


const style = props => ({
  root: {
    marginTop: 12,
  }
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  createExpenses,
}, dispatch);

export default withStyles(style)(connect(
  null,
  mapDispatchToProps
)(ImportButton));
