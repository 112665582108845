import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';

import groupBy from 'lodash/groupBy';
import { withStyles } from '@material-ui/core/styles';

import { selectExepnses } from '../redux/expenses';
import { selectUsers } from '../redux/projects';

const styles = theme => ({
  root: {
    width: '100%',
    height: 400,
  }
});

class UsersPie extends PureComponent {
  render() {
    if (!this.props.users || !this.props.expenses || !this.props.expenses.length) return null;
    const { users } = this.props;
    const groups = groupBy(this.props.expenses, 'userId');
    const data = Object.keys(groups).map((u) => ({id: u, label: (users && users[u]) ? (users[u].name || users[u].email) : null,
                                              value: groups[u].reduce((sum, i) => sum + i.price, 0)}));
    return (
      <div className={ this.props.classes.root } >
      <ResponsivePie
        data={ data }
        height={ 400 }
        sortByValue
        innerRadius={0.3}
        padAngle={1}
        cornerRadius={3}
        enableRadialLabels={ false }
        margin={{
            "top": 10,
            "right": 10,
            "bottom": 20,
            "left": 10
        }}
        />
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    expenses: (selectExepnses(state, ownProps.selectedMonth) || []).filter(
      e =>  ownProps.filter.includes(e.categoryId)),
    users: selectUsers(state),
  };
}

export default connect(
  mapStateToProps,
)(withStyles(styles)(UsersPie));
