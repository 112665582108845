import React, { PureComponent } from 'react';

import ExpensesExplorer from '../expenses_list/ExpensesExplorer';
import ExpensesListItem from '../expenses_list/ExpensesListItem';

import { withStyles } from '@material-ui/core/styles';

class Preview extends PureComponent {

  render() {
    const { expenses } = this.props

    return (
      <ExpensesExplorer expenses={expenses} renderItem={
        (expense) => {
          return <ExpensesListItem key={expense.id}
                                expense={expense} />
        }
      }/>
    );
  }
}

const style = theme => ({
});

export default withStyles(style)(Preview);
