import React, { PureComponent } from 'react';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import SelectFile from './SelectFile';
import MatchFields from './MatchFields';
import MatchUsers from './MatchUsers';
import Preview from './Preview';
import ImportButton from './ImportButton';

import { withStyles } from '@material-ui/core/styles';

class Import extends PureComponent {
  state = {
    mapping: {},
    user: {},
  }

  onDataLoad = (cols, data) => {
    this.setState({columns: cols, items: data});
  }

  onMappingChange = (name, value) => {
    this.setState(prev => {
      return {
        ...prev,
        mapping: {
          ...prev.mapping,
          [name]: value,
        }
      };
    })
  }

  onUserMappingChange = (sourceUser, targetUser) => {
    this.setState(prev => {
      return {
        ...prev,
        user: {
          ...prev.user,
          [sourceUser]: targetUser,
        }
      };
    })
  }

  buildExpenses = () => {
    if (!this.state.items) return null

    return this.state.items.map(item => {
      return {
        desc: item[this.state.mapping['desc']],
        price: item[this.state.mapping['price']],
        date: moment(item[this.state.mapping['date']], this.state.mapping['date_format']).format('DD-MM-YY'),
        userId: this.state.user[item[this.state.mapping['user']]] || this.state.user.default,
      }
    });
  }

  render() {
    const {
      columns,
      mapping,
      items,
      user,
    } = this.state;

    const expenses = this.buildExpenses();

    return (
      <Grid container justify="center" spacing={24}>
        <Grid item xs={12} sm={6}>
          <SelectFile onDataLoad={this.onDataLoad}/>
        </Grid>

        { columns && expenses &&
          <Grid item container xs={12} spacing={24}>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Grid container
                      direction="column"
                      alignItems="center"
                      className={this.props.classes.importForm}>

                  <Grid item container>
                    <MatchFields columns={columns} mapping={mapping} onMappingChange={this.onMappingChange}/>
                  </Grid>
                  { mapping && mapping.user && items &&
                      <Grid item container>
                        <MatchUsers items={items}
                                    mapping={mapping}
                                    onUserMappingChange={this.onUserMappingChange}
                                    userMapping={user}/>
                      </Grid>
                  }
                  <Grid item>
                    <ImportButton expenses={expenses}/>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Preview expenses={expenses}/>
            </Grid>
          </Grid>
        }
      </Grid>
    );
  }
}

const style = theme => ({
  importForm: {
    padding: 24,
  }
});

export default withStyles(style)(Import);
