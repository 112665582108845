import React, { Component } from 'react';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import CategoryColor from './CategoryColor';

import { withStyles } from '@material-ui/core/styles';

const style = props => ({
  textContainer: {
    flex: '1 1 auto',
    minWidth: 0,
    paddingRight: 32,
    paddingLeft: 16,
  },
  text: {
    width: '100%',
  },
  action: {
    position: 'absolute',
    right: 4,
    top: '50%',
    transform: 'translateY(-50%)',
  },
});

class AddCategory extends Component {
  state = {
    name: '',
    color: null,
  };

  handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value
    });
  }

   handleSubmit = (event) => {
     event.preventDefault();
     this.props.onAdd(this.state.color, this.state.name);
     this.setState({
       name: '',
       color: null,
     });
   }

  setColor = (color) => {
    this.setState({
      color: color.hex,
    });
  };

  render() {
    const { classes } = this.props;
    const { color, name } = this.state;

    return (
      <form onSubmit={this.handleSubmit} >
        <ListItem>
            <CategoryColor color={color} updateColor={this.setColor} />
            <div className={classes.textContainer}  >
              <TextField name='name'
                         type='text'
                         required={true}
                         value={name}
                         onChange={this.handleInputChange}
                         className={classes.text}  />
            </div>
            <div className={classes.action}  >
            <IconButton aria-label='Add' type='submit'>
              <AddIcon/>
            </IconButton>
            </div>
        </ListItem>
      </form>
    );
  }
}
export default withStyles(style)(AddCategory);
