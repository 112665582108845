import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';

import groupBy from 'lodash/groupBy';
import { withStyles } from '@material-ui/core/styles';

import { selectExepnses } from '../redux/expenses';
import { selectCategoriesMap } from '../redux/categories';

const styles = theme => ({
  root: {
    width: '100%',
    height: 400,
  }
});

class ExpensesPie extends PureComponent {
  onClick = (slice) => {
    const categoryId = slice.id === 'null' ? null : Number(slice.id)
    this.props.onCategoryClick(categoryId);
  }

  render() {
    if (!this.props.expenses || !this.props.expenses.length) return null;
    const groups = groupBy(this.props.expenses, 'categoryId');
    const data = Object.keys(groups).map((k) => ({id: k, label: this.props.categories[k].name,
                                            value: groups[k].reduce((sum, i) => sum + i.price, 0),
                                            color: this.props.categories[k].color}));
    return (
      <div className={ this.props.classes.root } >
        <ResponsivePie
          data={ data }
          height={ 400 }
          sortByValue
          innerRadius={0.3}
          padAngle={1}
          cornerRadius={3}
          colorBy={e => e.color}
          enableRadialLabels={ false }
          margin={{
              "top": 10,
              "right": 10,
              "bottom": 20,
              "left": 10
          }}
          isInteractive={true}
          onClick={this.onClick}
          />
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    expenses: (selectExepnses(state, ownProps.selectedMonth) || []).filter(
      e =>  ownProps.filter.includes(e.categoryId)),
    categories: selectCategoriesMap(state),
  };
}

export default connect(
  mapStateToProps,
)(withStyles(styles)(ExpensesPie));
