import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { DropTarget } from 'react-dnd';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';
import { withStylesProps } from '../utils/utils';
import { darken } from '@material-ui/core/styles/colorManipulator';

import { selectCategories } from '../redux/categories';

const categoryStyles = props => ({
  avatar: {
    background: props.category.color,
    padding: 1,
  },
  chip: {
    margin: 16,
  },
  notSelected: {
    filter: 'opacity(50%)',
  },
  over: {
    background: darken(props.category.color, 0.2),
  }
});

const dropTarget = {
  drop({category}) {
    return {category: category};
  }
};
function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

class CategoryNoStyle extends Component {
  handleClick = () => {
    this.props.handleClick(this.props.category.id, this.props.isSelected);
  }

  render() {
    const { category, classes, isSelected, connectDropTarget, isOver } = this.props;
    return connectDropTarget(
        <div><Chip
          avatar={<Avatar />}
          label={ category.name || 'Uncategorize' }
          onClick={this.handleClick}
          className={cn({[classes.notSelected]: !isSelected,
                         [classes.over]: isOver,
                       })}
          classes={{
            avatar: classes.avatar,
          }}
        /></div>
    );
  }
}

const Category = DropTarget('item', dropTarget, collect)(withStylesProps(categoryStyles)(CategoryNoStyle));

const styles = theme => ({
  root: {
    alignItems: 'center',
  },
  caption: {
    width: 100,
  },
});

class CategoriesFilter extends Component {
  handleClick = (categoryId, isSelected) => {
    if (isSelected) {
      this.props.removeFilter(categoryId);
    } else {
      this.props.addFilter(categoryId);
    }
  }

  render() {
    return (
      <Grid container justify="center" spacing={32} className={this.props.classes.root}>
        {this.props.categories.map((c) => {
            return (
              <Grid item key={ c.id }>
                <Category
                          category={c}
                          handleClick={this.handleClick}
                          isSelected={this.props.filter.includes(c.id)}/>
              </Grid>
            );
          })
        }
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    categories: selectCategories(state),
  };
}

export default withStyles(styles)(connect(
  mapStateToProps,
)(withStyles(styles)(CategoriesFilter)));
