import { onError, setCurrentProject } from "./app";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

function login(user, token) {
  return { type: LOGIN, user, token };
}

async function create(accessToken) {
  const tokenBlob = new Blob(
    [JSON.stringify({ access_token: accessToken }, null, 2)],
    { type: "application/json" }
  );
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/auth/google`,
    {
      method: "POST",
      body: tokenBlob,
      mode: "cors",
      cache: "default",
    }
  );

  const token = response.headers.get("x-auth-token");
  const projectId = response.headers.get("project_id");
  const body = await response.json();

  if (response.status !== 200) throw Error(body);
  return [body, token, projectId];
}

export function createToken(response) {
  return (dispatch) => {
    return create(response.credential).then(
      ([user, token, projectId]) => {
        localStorage.setItem("jwt_token", token);
        localStorage.setItem("user_id", user.id);
        localStorage.setItem("user_avatar", user.avatar);
        localStorage.setItem("current_project_id", projectId);
        dispatch(login(user, token));
        dispatch(setCurrentProject(projectId));
      },
      (error) => dispatch(onError(error))
    );
  };
}

export function logout() {
  localStorage.removeItem("jwt_token", null);
  return { type: LOGOUT };
}

export default function reducer(
  state = {
    isAuthenticated: localStorage.getItem("jwt_token") ? true : false,
    user: {
      id: localStorage.getItem("user_id"),
      avatar: localStorage.getItem("user_avatar"),
    },
  },
  action
) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.user,
        token: action.token,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
      };
    default:
      return state;
  }
}

export const selectToken = (state) => {
  return state.auth.token;
};
export const selectUser = (state) => {
  return state.auth.user;
};
export const selectIsAuthenticated = (state) => {
  return state.auth.isAuthenticated;
};
