import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import MonthsPager from '../explore/MonthsPager';
import RecurringIncomeTable from './RecurringIncomeTable';
import IncomeForm from './IncomeForm';
import IncomeTable from './IncomeTable';
import { withStyles } from '@material-ui/core/styles';

import { nextMonth, prevMonth } from '../utils/dates';
import { fetchIncomes, fetchRecurringIncomes } from '../redux/incomes';

const styles = theme => ({
});

class IncomeView extends PureComponent {
  state = {
    selectedMonth: moment(new Date()).startOf('month'),
  };


 componentDidMount() {
   this.props.fetchIncomes(this.state.selectedMonth);
   this.props.fetchRecurringIncomes();
 }

  selectPrevMonth = () => {
    this.setState( (prev) => {
      return {
        selectedMonth: prevMonth(prev.selectedMonth),
      };
    }, () => {
      this.props.fetchIncomes(this.state.selectedMonth);
    });
  }

  selectNextMonth = () => {
    this.setState( (prev) => {
      return {
        selectedMonth: nextMonth(prev.selectedMonth),
      };
    }, () => {
      this.props.fetchIncomes(this.state.selectedMonth);
    });
  }

  render() {
    return (
      <Grid container spacing={24}>
        <Grid item xs={12} sm={4}>
          <IncomeForm />
        </Grid>
        <Grid container item xs={12} sm={8} spacing={24} direction="column">
          <Grid item>
            <RecurringIncomeTable />
          </Grid>
          <Grid item>
            <MonthsPager handleBackButtonClick={this.selectPrevMonth}
                         currentMonth={this.state.selectedMonth}
                         handleNextButtonClick={this.selectNextMonth}
                         />
          </Grid>
          <Grid item>
            <IncomeTable selectedMonth={this.state.selectedMonth}/>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  fetchRecurringIncomes,
  fetchIncomes,
}, dispatch);

export default withStyles(styles)(connect(
  null,
  mapDispatchToProps
)(IncomeView));
